import React, { useContext } from 'react'
import { Route, RouteComponentProps, Redirect } from 'react-router-dom'

import { TokenContext } from '../contexts/Token'
import NavBarComponent from '../components/NavBar'
import CompanyProvider from '../contexts/Company'

interface RouteProps {
  isPublic?: boolean
  path: string | string[]
  exact?: boolean
  component:
  | React.ComponentType<RouteComponentProps<{ id?: string }>>
  | React.ComponentType
}

const RouteWrapper: React.FunctionComponent<RouteProps> = ({
  isPublic,
  path,
  exact,
  component: Component,
  ...remaining
}) => {
  const RRoute = Route as unknown as any
  const RRedirect = Redirect as unknown as React.FC<{ to: string }>


  const tokenContext = useContext(TokenContext)

  if (tokenContext.token === undefined) {
    return <div />
  }

  if (!tokenContext.token && !isPublic) {
    return <RRedirect to="/" />
  }

  if (tokenContext.token && isPublic) {
    return <RRedirect to="/rascunhos" />
  }

  if (tokenContext.token && !isPublic) {
    let currentMenu = ''
    const menus = [
      { path: 'rascunhos', button: 'sketches' },
      { path: 'notas-fiscais', button: 'invoices' },
      { path: 'clientes', button: 'consumers' },
      { path: 'itens', button: 'items' },
      { path: 'emissores', button: 'companies' }
    ]
    menus.forEach((menu) => {
      if (path.includes(menu.path)) {
        currentMenu = menu.button
      }
    })

    return (
      <CompanyProvider>
        <NavBarComponent menuActived={currentMenu} />
        <div className="container">
          <RRoute
            path={path}
            exact={exact}
            render={(props: any) => <Component {...props} />}
            {...remaining}
          />
        </div>
      </CompanyProvider>
    )
  }

  return (
    <RRoute
      path={path}
      exact={exact}
      render={(props: any) => <Component {...props} />}
      {...remaining}
    />
  )
}

export default RouteWrapper
