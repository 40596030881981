import React from 'react'
import { Router } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import TokenContextProvider from './contexts/Token'
import ApiContextProvider from './contexts/Api'
import Routes from './routes'
import history from './services/history'
import { applicationTheme } from './theme'
import UserTypeContextProvider from './contexts/UserType'

const App: React.FC = () => {
  const RRouter = Router as unknown as any

  return (
    <ChakraProvider theme={applicationTheme}>
      <TokenContextProvider>
        <UserTypeContextProvider>
          <ApiContextProvider>
            <RRouter history={history}>
              <Routes />
            </RRouter>
          </ApiContextProvider>
        </UserTypeContextProvider>
      </TokenContextProvider>
    </ChakraProvider>
  )
}

export default App
