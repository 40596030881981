import React from 'react'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import InputMask from './InputMask'
import InputMoney from './InputMoney'
import { Mask } from 'react-text-mask'

interface InputBlockProperties {
  autoFocus?: boolean
  isRequired?: boolean
  money?: boolean
  name: string
  label: string
  type?: 'email' | 'password' | 'number'
  value: string | number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  marginTop?: boolean
  uppercase?: boolean
  mask?: Mask | ((value: string) => Mask)
  maskChar?: string
}

const InputBlock: React.FC<InputBlockProperties> = ({
  autoFocus,
  isRequired,
  money,
  name,
  label,
  type,
  value,
  onChange,
  marginTop,
  uppercase,
  mask,
  onFocus,
  onBlur
}) => (
  <FormControl isRequired={isRequired} marginTop={marginTop ? 2 : undefined}>
    <FormLabel htmlFor={name} marginLeft={2} marginBottom={-1}>
      {label}
    </FormLabel>
    {money && (
      <InputMoney
        mask={['']}
        autoFocus={autoFocus}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )}
    {mask && (
      <InputMask
        autoFocus={autoFocus}
        mask={mask}
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )}
    {money === undefined && mask === undefined && (
      <Input
        autoFocus={autoFocus}
        background="white"
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        textTransform={uppercase ? 'uppercase' : undefined}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )}
  </FormControl>
)

export default InputBlock
