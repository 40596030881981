import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import Login from '../pages/Login'

import Sketches from '../pages/Sketches'
import EditSketch from '../pages/Sketches/Edit'

import Invoices from '../pages/Invoices'
import ShowInvoice from '../pages/Invoices/Show'

import Consumers from '../pages/Consumers'
import CreateConsumer from '../pages/Consumers/Create'
import ShowConsumer from '../pages/Consumers/Show'
import EditConsumer from '../pages/Consumers/Edit'

import Items from '../pages/Items'
import CreateProduct from '../pages/Items/Create/Product'
import CreateService from '../pages/Items/Create/Service'
import ShowItem from '../pages/Items/Show'

import Configurations from '../pages/Configurations'
import ChangePassword from '../pages/Configurations/ChangePassword'
import UpdateRegistration from '../pages/Configurations/UpdateRegistration'

import Companies from '../pages/Companies'
import CreateCompanie from '../pages/Companies/Create'

const Routes: React.FunctionComponent = () => {
  const RSwitch = Switch as unknown as React.FC
  return (
    <RSwitch>
      <Route path="/" exact component={Login} isPublic />

      <Route path="/rascunhos" exact component={Sketches} />
      <Route path="/rascunhos/:id" component={EditSketch} />

      <Route path="/notas-fiscais" exact component={Invoices} />
      <Route path="/notas-fiscais/:id" component={ShowInvoice} />

      <Route path="/clientes" exact component={Consumers} />
      <Route path="/clientes/novo" exact component={CreateConsumer} />
      <Route path="/clientes/:id" exact component={ShowConsumer} />
      <Route path="/clientes/:id/editar" component={EditConsumer} />

      <Route path="/itens" exact component={Items} />
      <Route path="/itens/novo/produto" exact component={CreateProduct} />
      <Route path="/itens/novo/servico" exact component={CreateService} />
      <Route path="/itens/:id" component={ShowItem} />

      <Route path="/configuracoes" exact component={Configurations} />
      <Route path="/configuracoes/alterar-senha" component={ChangePassword} />
      <Route
        path="/configuracoes/atualizar-cadastro"
        component={UpdateRegistration}
      />

      <Route path="/emissores" exact component={Companies} />
      <Route path="/emissores/novo" exact component={CreateCompanie} />
    </RSwitch>
  )
}

export default Routes
